import {Suspense} from 'react';
import {useSelector} from 'react-redux';
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {treeToFlat} from './utils/Functions';
import {useTranslation} from 'react-i18next';
import {LoadingSpinner} from './components/Loading';
import useCheckLogin from './hooks/useCheckLogin';
import Header from './components/Header';
import Footer from './components/Footer';
import RoutesArray from './utils/RoutesArray';
import React from 'react';
import {ToastContainer} from 'react-toastify';

const ProtectedRoute = ({
                          isAllowed,
                          redirectPath = '/',
                          Component,
                          title = '',
                        }) => {
  if (isAllowed === false) return <Navigate to={redirectPath} replace/>;

  //dynamic title
  window.document.title = `${title} | Shopiget`;

  return Component ? (
      <Suspense>
        <Component/>
      </Suspense>
  ) : (
      <Outlet/>
  );
};

export default function App() {
  const {t} = useTranslation('common');
  const userData = useSelector((state) => state.user.userData);
  const {loading} = useCheckLogin();


  if (!localStorage.getItem('lang')) {
    localStorage.setItem('lang', navigator.language.split('-')[0]);
  }


  if (loading) {
    return (
        <div className="page">
          <LoadingSpinner status={true}/>
        </div>
    );
  }

  return (
      <div className="page">
        <ToastContainer/>
        <Header userData={userData}/>
        <Routes>
          {treeToFlat(RoutesArray).map((item, key) => {
            return (
                <Route
                    key={key}
                    path={item.path}
                    element={
                      <ProtectedRoute
                          redirectPath="/auth/login"
                          isAllowed={
                            item.auth
                                ? userData.isLogin &&
                                (item.permissions.length === 0 ||
                                    userData.data.permissions.some((x) =>
                                        item.permissions.includes(x),
                                    ))
                                : true
                          }
                          Component={item.Component}
                          title={t(item.title_index)}
                      />
                    }
                />
            );
          })}
        </Routes>
        <Footer/>
      </div>
  );
}
