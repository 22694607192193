import axios from "../utils/CustomAxios";

const CategoryAPI = {
  create: (data) => {
    return axios.post("v1/panel/categories", data);
  },
  get: (id) => {
    return axios.get("v1/panel/categories/" + id);
  },
  getAll: (params = {}) => {
    return axios.get("v1/panel/categories", { params });
  },

  delete: (id) => {
    return axios.delete("v1/panel/categories/" + id);
  },
  update: (id, data) => {
    return axios.put("v1/panel/categories/" + id, data);
  },
};

export default CategoryAPI;
