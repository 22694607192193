import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  messageQueues: [],
};

export const messageSlice = createSlice({
  name: "message",
  initialState: initialState,
  reducers: {
    addMessage: (state, { payload }) => {
      state.messageQueues.push(payload);
    },
    deleteMessage: (state, { payload }) => {
      state.messageQueues = state.messageQueues.filter((x) => x.id !== payload);
    },
  },
});

//promise olmayan işlerde normal reducer
export const { addMessage, deleteMessage } = messageSlice.actions;

//promise işlerde thunk oluşturulacak
//mesajı kuyruga ekleme
export const addMessageQueue = createAsyncThunk(
  "message/addMessageQueue",
  async (payload, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    // const state = thunkAPI.getState().message;

    let random_id = Math.random().toString(36).substring(7);

    //mesajı kuyruga ekle
    dispatch(addMessage({ id: random_id, ...payload }));

    //belli saniye sonra mesajı kaldır
    await new Promise(function (resolve) {
      setTimeout(() => {
        dispatch(deleteMessage(random_id));
        resolve();
      }, payload.second * 1000);
    });
  }
);

//mesajları kuyruğa ekleme
export const addMessagesQueue = createAsyncThunk(
  "message/addMessagesQueue",
  async (payload, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;

    payload.forEach(async (x) => {
      let random_id = Math.random().toString(36).substring(7);

      //mesajı kuyruga ekle
      dispatch(addMessage({ id: random_id, ...x }));

      //belli saniye sonra mesajı kaldır
      await new Promise(function (resolve) {
        setTimeout(() => {
          dispatch(deleteMessage(random_id));
          resolve();
        }, x.second * 1000);
      });
    });
  }
);

export default messageSlice.reducer;
