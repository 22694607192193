import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RoutesArray from "../utils/RoutesArray";
import FastProductForm from "./forms/FastProductForm";
//p1:route permissions,p2:user permissions
const comparePermissions = (p1, p2) => {
  if (
    p1.length === 0 ||
    p2.some((userPermissions) => p1.includes(userPermissions))
  ) {
    return true;
  } else {
    return false;
  }
};

function NavBar({ userData }) {
  const { t } = useTranslation("common");
  const [openMenus, setOpenMenus] = useState([]);

  const handleClick = (item) => {
    setOpenMenus([]);
    if (!item.children || item.children.length === 0) {
      setOpenMenus([]);
    } else {
      setOpenMenus([...openMenus, item.title_index]);
    }
  };

  const isMenuOpen = (item) => {
    return openMenus.includes(item.title_index);
  };

  return (
    <>
      <div className="navbar-expand-md">
        <div className="collapse navbar-collapse" id="navbar-menu">
          <div className="navbar navbar-light">
            <div className="container-xl">
              <ul className="navbar-nav">
                {RoutesArray.filter(
                  (r) =>
                    r.menu &&
                    comparePermissions(r.permissions, userData.data.permissions)
                ).map((item, key) => {
                  return (
                    <li
                      key={key}
                      className={
                        "nav-item " +
                        (item.children &&
                          item.children.length > 0 &&
                          "dropdown")
                      }
                    >
                      <Link
                        to={
                          (!item.children || item.children.length === 0) &&
                          item.path
                        }
                        className={
                          "nav-link " +
                          (item.children &&
                            item.children.length > 0 &&
                            " dropdown-toggle")
                        }
                        data-bs-toggle={
                          item.children &&
                          item.children.length > 0 &&
                          "dropdown"
                        }
                        data-bs-auto-close="outside"
                        role="button"
                        aria-expanded={isMenuOpen(item) ? "true" : "false"}
                        onClick={() => handleClick(item)}
                      >
                        <span
                          className="nav-link-icon d-md-none d-lg-inline-block"
                          style={{ fontSize: "1.25rem" }}
                        >
                          {item.icon}
                        </span>
                        <span className="nav-link-title">
                          {t(item.title_index)}
                        </span>
                      </Link>

                      <div
                        className={
                          "dropdown-menu " + (isMenuOpen(item) ? "show" : "")
                        }
                      >
                        {item.children && item.children.length > 0 && (
                          <div className="dropdown-menu-column">
                            {item.children
                              .map((i) => {
                                if (i.bordered) {
                                  return { ...i, title: `zzz_${i.title}` };
                                } else return { ...i };
                              })
                              .sort((a, b) =>
                                a.title.localeCompare(b.title, "tr")
                              )
                              .map((i) => {
                                const firstFour = i.title
                                  .split("")
                                  .slice(0, 4)
                                  .join("");
                                if (firstFour === "zzz_") {
                                  const _new = i.title
                                    .split("")
                                    .slice(4)
                                    .join("");

                                  return { ...i, title: _new };
                                } else return { ...i };
                              })
                              .filter(
                                (r) =>
                                  r.menu &&
                                  comparePermissions(
                                    r.permissions,
                                    userData.data.permissions
                                  )
                              )
                              .map((item2, key2) => {
                                const style = `${
                                  item2.bordered &&
                                  item2.first &&
                                  " border-top "
                                }`;

                                return (
                                  <div key={key2} className="dropend">
                                    <Link
                                      to={item2.path}
                                      className={
                                        " dropdown-item " +
                                        (item2.children &&
                                        item2.children.length > 0
                                          ? " dropdown-toggle "
                                          : "") +
                                        style
                                      }
                                      data-bs-toggle={
                                        item2.children &&
                                        item2.children.length > 0 &&
                                        "dropdown"
                                      }
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                      role="button"
                                      aria-expanded={
                                        isMenuOpen(item2) ? "true" : "false"
                                      }
                                      onClick={() => handleClick(item2)}
                                    >
                                      <span>{t(item2.title_index)}</span>
                                      {item2.icon && (
                                        <span
                                          className="nav-link-icon d-md-none d-lg-inline-block"
                                          style={{
                                            fontSize: "1.5em",
                                            color: "#f28c28",
                                            marginLeft: "1em",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          {item2.icon}
                                        </span>
                                      )}
                                    </Link>
                                    {item2.children &&
                                      item2.children.length > 0 && (
                                        <div
                                          className={
                                            "dropdown-menu " +
                                            (isMenuOpen(item2) ? "show" : "")
                                          }
                                          data-bs-popper="none"
                                        >
                                          {item2.children
                                            .filter(
                                              (x) =>
                                                x.menu &&
                                                comparePermissions(
                                                  x.permissions,
                                                  userData.data.permissions
                                                )
                                            )
                                            .map((item3, key3) => {
                                              return (
                                                <div
                                                  key={key3}
                                                  className="dropend"
                                                >
                                                  <Link
                                                    to={item3.path}
                                                    className={
                                                      "dropdown-item " +
                                                      (item3.children &&
                                                      item3.children.length > 0
                                                        ? " dropdown-toggle "
                                                        : "")
                                                    }
                                                    data-bs-toggle={
                                                      item3.children &&
                                                      item3.children.length >
                                                        0 &&
                                                      "dropdown"
                                                    }
                                                    role="button"
                                                    aria-expanded={
                                                      isMenuOpen(item3)
                                                        ? "true"
                                                        : "false"
                                                    }
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      handleClick(item3);
                                                    }}
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <span>
                                                      {t(item3.title_index)}
                                                    </span>
                                                    {item3.icon && (
                                                      <span
                                                        className="nav-link-icon d-md-none d-lg-inline-block"
                                                        style={{
                                                          fontSize: "1.5em",
                                                          color: "#f28c28",
                                                          marginLeft: "1em",
                                                          verticalAlign:
                                                            "middle",
                                                        }}
                                                      >
                                                        {item3.icon}
                                                      </span>
                                                    )}
                                                  </Link>

                                                  {item3.children &&
                                                    item3.children.length >
                                                      0 && (
                                                      <div
                                                        className={
                                                          "dropdown-menu " +
                                                          (isMenuOpen(item3)
                                                            ? "show"
                                                            : "")
                                                        }
                                                        data-bs-popper="none"
                                                      >
                                                        {item3.children
                                                          .filter(
                                                            (x) =>
                                                              x.menu &&
                                                              comparePermissions(
                                                                x.permissions,
                                                                userData.data
                                                                  .permissions
                                                              )
                                                          )
                                                          .map(
                                                            (item4, key4) => {
                                                              return (
                                                                <div
                                                                  key={key4}
                                                                  className="dropend"
                                                                >
                                                                  <Link
                                                                    to={
                                                                      item4.path
                                                                    }
                                                                    className={
                                                                      "dropdown-item " +
                                                                      (item4.children &&
                                                                      item4
                                                                        .children
                                                                        .length >
                                                                        0
                                                                        ? " dropdown-toggle "
                                                                        : "") +
                                                                      style
                                                                    }
                                                                    data-bs-toggle={
                                                                      item4.children &&
                                                                      item4
                                                                        .children
                                                                        .length >
                                                                        0 &&
                                                                      "dropdown"
                                                                    }
                                                                    role="button"
                                                                    aria-expanded={
                                                                      isMenuOpen(
                                                                        item4
                                                                      )
                                                                        ? "true"
                                                                        : "false"
                                                                    }
                                                                    onClick={() =>
                                                                      handleClick(
                                                                        item4
                                                                      )
                                                                    }
                                                                  >
                                                                    {t(
                                                                      item4.title_index
                                                                    )}
                                                                  </Link>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                      </div>
                                                    )}
                                                </div>
                                              );
                                            })}
                                        </div>
                                      )}
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(NavBar);
