import Button from "../../components/Button";
import Switch from "../../components/Switch";
import { ArrowsMove, TrashIcon } from "../../components/Icons";
import { useSelector } from "react-redux";
import { CSS } from "@dnd-kit/utilities";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { useMemo } from "react";
import axios from "../../utils/CustomAxios";
import { warningToast } from "../../utils/toastNotification";

const SortableItem = ({
  id,
  data,
  url,
  onChangeImageValue,
  optionCombinationIndex,
  optionCombinationValueIndex,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: id,
  });

  const deleteFile = (name) => {
    axios
      .get("v1/files/", {
        params: {
          name: name,
        },
      })
      .then((res) => {
        if (res.data.status === 1) {
          axios.delete("v1/files/" + res.data.data.id).then((res2) => {
            if (res2.data.status === 1) {
              for (let i = localStorage.length - 1; i >= 0; i--) {
                const key = localStorage.key(i);
                if (key && key.startsWith("image")) {
                  const value = localStorage.getItem(key);
                  if (value === name) {
                    localStorage.removeItem(key);
                  }
                }
              }
            }
          });
        }
      });
  };

  return (
    <div
      ref={setNodeRef}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.75 : undefined,
        // zIndex: isDragging ? 9999 : "none",
      }}
      className="position-relative col-12 col-sm-4 bg-white p-0"
    >
      <div className="row">
        <div className="col-12">
          <img
            src={url}
            alt="product-image"
            className="mb-0"
            style={{ objectFit: "cover" }}
            width="100%"
            height="350"
            {...attributes}
            {...listeners}
          />
        </div>
        <div className="col-12">
          <select
            className="form-control border-top-0 rounded-0"
            value={data.type}
            onChange={(e) =>
              onChangeImageValue(
                optionCombinationIndex,
                optionCombinationValueIndex,
                "type",
                parseInt(e.target.value)
              )
            }
          >
            <option value={0}>Normal</option>
            <option value={1}>Ön</option>
            <option value={2}>Arka</option>
            <option value={3}>Numune</option>
          </select>
        </div>
      </div>

      <div className="position-absolute top-0 start-0 p-2 w-100">
        <div className="row">
          <div className="col-6 d-flex">
            <Switch
              status={data.status}
              setStatus={(bool) =>
                onChangeImageValue(
                  optionCombinationIndex,
                  optionCombinationValueIndex,
                  "status",
                  bool
                )
              }
            />
          </div>
          <div className="col-6 d-flex justify-content-end">
            <Button
              className="border-0 btn btn-sm btn-white btn-icon me-2"
              icon={<TrashIcon className="text-dark" />}
              type="normal"
              onClick={() => {
                deleteFile(data.image);
                onChangeImageValue(
                  optionCombinationIndex,
                  optionCombinationValueIndex,
                  "deleted",
                  true
                );
              }}
            />

            {/* <button
              className="border-0 btn btn-sm btn-secondary btn-icon"
              {...attributes}
              {...listeners}
            >
              <ArrowsMove />
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const PSIMarketplacesDirectLink = ({
  items,
  setItems,
  onChangeImageValue,
  optionCombinationIndex,
  t,
}) => {
  const { shopDetail } = useSelector((state) => state.shop);

  const cachedItems = useMemo(() => items.map((item) => item.image), [items]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = cachedItems.indexOf(active.id);
      const newIndex = cachedItems.indexOf(over.id);
      const _items = arrayMove(items, oldIndex, newIndex);
      setItems(_items);
    }
  };

  if (items.length === 0) return null;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={cachedItems}
        strategy={verticalListSortingStrategy}
      >
        <div className="row">
          {items.map((item, key) => (
            <SortableItem
              key={item.image}
              id={item.image}
              data={item}
              url={item.image}
              onChangeImageValue={onChangeImageValue}
              optionCombinationIndex={optionCombinationIndex}
              optionCombinationValueIndex={key}
              t={t}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default PSIMarketplacesDirectLink;
