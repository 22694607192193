import axios from "../utils/CustomAxios";

const MarketplacesAPI = {
  getAll: () => {
    return axios.get("/v1/panel/marketplaces");
  },
  get: (ID) => {
    return axios.get("/v1/panel/marketplaces/" + ID);
  },
  update: (ID, data) => {
    return axios.put("v1/panel/marketplaces/" + ID, data);
  },
};

export default MarketplacesAPI;
