/*

bg-*

bg-facebook

*/

export default function Avatar({ className = "", children }) {
  return <span className={className + " avatar"}>{children}</span>;
}
