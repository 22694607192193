import axios from "../utils/CustomAxios";

const LanguageAPI = {
  // create: (data) => {
  //   return axios.post("v1/panel/taxes", data); 
  // },
  // get: (id) => {
  //   return axios.get("v1/panel/taxes/" + id);
  // },
  getAll: (data = {}) => {
    return axios.get("v1/panel/languages", { 
      params: { type: "available", ...data },
    });
  },
  // delete: (id) => {
  //   return axios.delete("v1/panel/taxes/" + id);
  // },
  // update: (id, data) => {
  //   return axios.put("v1/panel/taxes/" + id, data);
  // },
};

export default LanguageAPI;
