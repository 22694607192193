import axios from "../utils/CustomAxios";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  MdOutlinePublic,
  MdOutlineSettings,
  MdOutlineLanguage,
  MdOutlineNotifications,
  MdOutlinePerson,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../redux/slices/userSlice";
import NavBar from "./NavBar";
import Avatar from "./Avatar";
import { NotificationMenu } from "./header/NotificationMenu";
import { AccountMenu } from "./header/AccountMenu";

const expectRoutes = [
  "/404",
  "/auth/login",
  "/auth/register",
  "/auth/forget-password",
  "/auth/reset-password",
  "/shops/add",
];

export default function Header({ userData }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { domain } = useSelector((state) => state.shop.shopDetail);
  const { pathname } = useLocation();
  const { t } = useTranslation("common");
  const [visibleIndex, setVisibleIndex] = useState(0);
  const navbarRef = useRef(null);

  const { shopDetail } = useSelector((state) => state.shop);

  const [notificationCount, setNotificationCount] = useState(0);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const fetchNotificationCount = async () => {
      try {
        const response = await axios.get("v1/panel/notifications");

        if (response.data.status === 1) {
          setNotificationCount(response.data.data.totalCount);
        }
      } catch (error) {
        console.error("Error fetching notification count:", error);
      }
    };
    fetchNotificationCount();
  }, []);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get(
          "v1/panel/shop-modules/languages-for-header",
          { params: { type: "language" } }
        );
        if (response.data.status === 1) {
          setLanguages(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };
    fetchLanguages();
  }, []);

  const changeVisibleIndex = (index) => {
    setVisibleIndex(index === visibleIndex ? 0 : index);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setVisibleIndex(0);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const resetPasswordRegex = /^\/auth\/reset-password\/.+$/;

  if (expectRoutes.includes(pathname) || resetPasswordRegex.test(pathname)) {
    return null;
  }
  return (
    <>
      <header className="navbar navbar-expand-md navbar-light d-print-none">
        <div className="container-xl">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-menu"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
            <Link to="/">
              <img
                src="/dist/img/logo.png"
                width="90"
                height="20"
                alt="shopiget-logo"
              />
            </Link>
          </h1>
          <div className="navbar-nav flex-row order-md-last" ref={navbarRef}>
            <div className="d-flex">
              <div className="nav-item dropdown me-2">
                <a
                  className="nav-link px-0 cursor-pointer"
                  href={`https://${domain}`}
                  target="_blank"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    transition: "transform 0.3s ease",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "scale(1.2)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >
                  <Avatar className="avatar-sm">
                    <MdOutlinePublic />
                  </Avatar>
                </a>
              </div>
            </div>

            <div className="nav-item dropdown d-flex me-2">
              <span
                className="nav-link px-0 cursor-pointer"
                onClick={() => changeVisibleIndex(1)}
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  transition: "transform 0.3s ease",
                  position: "relative",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                }}
              >
                <Avatar className="avatar-sm">
                  <MdOutlineNotifications />
                </Avatar>
                {notificationCount > 0 && (
                  <span
                    className="badge bg-red position-absolute bottom-0 end-0"
                    style={{ transform: "translate(50%, -50%)" }}
                  ></span>
                )}
              </span>
              {visibleIndex === 1 && (
                <NotificationMenu setVisibleIndex={setVisibleIndex} />
              )}
            </div>

            <div className="nav-item dropdown">
              <span
                className="nav-link d-flex lh-1 text-reset p-0 cursor-pointer"
                onClick={() => changeVisibleIndex(3)}
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    transition: "transform 0.3s ease",
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "scale(1.2)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >
                  <Avatar className="avatar-sm">
                    <MdOutlinePerson />
                  </Avatar>
                </div>
                <div className="d-none d-xl-block ps-2">
                  <div className="text-muted">
                    {userData.data?.name + " " + userData.data?.surname}
                  </div>
                  <div className="mt-1 small text-muted">{t("my_account")}</div>
                </div>
              </span>
              {visibleIndex === 3 && (
                <AccountMenu
                  setVisibleIndex={setVisibleIndex}
                  dispatch={dispatch}
                  languages={languages}
                />
              )}
            </div>
          </div>
        </div>
      </header>

      <NavBar userData={userData} />
    </>
  );
}
