import { Link } from "react-router-dom";
import { LoadingIcon } from "./Icons";
import { useTranslation } from "react-i18next";
/*

btn-*


types
icon
pill
square
ghost-*
outline-*

primary
secondary
success
warning
danger
info
ligth
dark
blue
azure
indigo
purple
pink
red
orange
yellow
lime
green
teal
cyan


facebook
twitter
google
youtube
vimeo
dribbble
github
instagram
pinterest
vk
rss
flickr
bitbucket
tabler

*/

export default function Button({
  icon = null,
  left_icon = null,
  right_icon = null,
  style,
  text = "",
  translation_text,
  loading = false,
  onClick = () => {},
  className,
  to = "#",
  type = "normal",
  title = "",
  disabled = false,
}) {
  const { t } = useTranslation("common");
  const apply = (event) => {
    if (!loading) {
      onClick(event);
    }
  };

  if (type === "normal") {
    return (
      <button
        className={"btn w-auto2 " + className}
        style={{ ...style }}
        onClick={apply}
        title={title}
        disabled={disabled}
      >
        {loading ? <LoadingIcon /> : icon}
        {left_icon}
        <span>{t(translation_text)}</span>
        <span>{text}</span>
        {right_icon}
      </button>
    );
  } else {
    return (
      <Link className={"btn w-auto2 " + className} style={{ ...style }} to={to}>
        {loading ? <LoadingIcon /> : icon}
        <span>{text}</span>
      </Link>
    );
  }
}
