import { toast } from "react-toastify";

export const successToast = (t, process, override = false) => {
  if (override) {
    toast.success(`${process}!`);
    return;
  }
  const successMessage = t(process);
  toast.success(`${successMessage}!`, {
    autoClose: 1500,
  });
};

export const errorToast = (t, process, override = false) => {
  if (override) {
    toast.error(`${process}!`);
    return;
  }
  const errorMessage = t(process);
  toast.error(`${errorMessage}!`);
};

export const warningToast = (t, process, override = false) => {
  if (override) {
    toast.warning(`${process}!`);
    return;
  }
  const warningMessage = t(process);
  toast.warning(`${warningMessage}!`);
};

export const infoToast = (t, process, override = false) => {
  if (override) {
    toast.info(`${process}!`);
    return;
  }
  const infoMessage = t(process);
  toast.info(`${infoMessage}!`);
};
