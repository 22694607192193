export function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader?.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export function generateTree(list, parent_id = null) {
  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parent_id !== parent_id) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parent_id]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

export function getTimeSince(date, t) {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);
  var interval = seconds / 31536000;

  if (interval > 1) {
    return t("time.since_year", { interval: Math.floor(interval) });
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return t("time.since_month", { interval: Math.floor(interval) });
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return t("time.since_day", { interval: Math.floor(interval) });
    // return Math.floor(interval) + " Gün önce";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return t("time.since_hour", { interval: Math.floor(interval) });
    // return Math.floor(interval) + " Saat önce";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return t("time.since_minute", { interval: Math.floor(interval) });
  }

  return t("time.since_second", {
    interval: Math.floor(seconds === 0 ? 1 : seconds),
  });
}

export function getTokensWithDefault() {
  let tokens = localStorage.getItem("tokens");

  if (tokens) {
    try {
      tokens = JSON.parse(tokens);

      if (Array.isArray(tokens)) {
        const defaultToken = tokens.find((x) => x.default);

        if (defaultToken !== undefined) {
          return [true, tokens, defaultToken];
        }
      }
    } catch (err) {
      localStorage.removeItem("tokens");
    }
  }

  return [false];
}

export function getCurrentToken() {
  let token = localStorage.getItem("current_token");
  if (token) {
    token = JSON.parse(token);
  }
  return token;
}

export function getLocale() {
  let country = localStorage.getItem("country_id");
  let language = localStorage.getItem("language_id");
  let currency = localStorage.getItem("currency_id");

  return {
    currency_id: currency,
    country_id: country,
    language_id: language,
  };
}

export function getImagePath(image, shop_id) {
  return `${process.env.REACT_APP_CDN_LINK}${shop_id}/${image}`;
}

export function treeToFlat(array) {
  let newArray = [];

  for (let i = 0; i < array.length; i++) {
    newArray.push(array[i]);

    if (array[i].children !== undefined) {
      for (let j = 0; j < array[i].children.length; j++) {
        newArray.push(array[i].children[j]);

        if (array[i].children[j].children) {
          for (let z = 0; z < array[i].children[j].children.length; z++) {
            newArray.push(array[i].children[j].children[z]);

            if (array[i].children[j].children[z].children) {
              for (
                let a = 0;
                a < array[i].children[j].children[z].children.length;
                a++
              ) {
                newArray.push(array[i].children[j].children[z].children[a]);
              }
            }
          }
        }
      }
    }
  }

  return newArray;
}

export function validateEmail(email) {
  return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
    email
  );
}

export function findParentsTree(node, searchForID) {
  // If current node name matches the search name, return
  // empty array which is the beginning of our parent result
  if (node.id === searchForID) {
    return [];
  }

  // Otherwise, if this node has a tree field/value, recursively
  // process the nodes in this tree array
  if (Array.isArray(node.children)) {
    for (var treeNode of node.children) {
      // Recursively process treeNode. If an array result is
      // returned, then add the treeNode.name to that result
      // and return recursively
      const childResult = findParentsTree(treeNode, searchForID);

      if (Array.isArray(childResult)) {
        return [treeNode.id].concat(childResult);
      }
    }
  }
}
