import { v4 as uuidv4 } from "uuid";
import { useRef, useEffect } from "react";
import { UploadIcon } from "./Icons";
import { LoadingSpinner } from "./Loading";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { errorToast } from "../utils/toastNotification";

FilesDragAndDrop.propTypes = {
  onUpload: PropTypes.func.isRequired,
};

export default function FilesDragAndDrop({
  id = uuidv4(),
  count,
  formats,
  loading,
  onUpload,
}) {
  const { t } = useTranslation("common");
  const drop = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop2 = (e) => {
    e.preventDefault();

    // this is required to convert FileList object to array
    const files = [...e.target.files];

    // check if the provided count prop is less than uploaded count of files
    if (count && count < files.length) {
      const plural = count !== 1 ? "s" : "";
      const message = t("files.allowed_number", { count, plural });
      errorToast(t, message, true);
      return;
    }

    // check if some uploaded file is not in one of the allowed formats
    if (
      formats &&
      files.some(
        (file) =>
          !formats.some((format) =>
            file.name.toLowerCase().endsWith(format.toLowerCase())
          )
      )
    ) {
      const message = t("files.allowed_formats", {
        formats: formats.join(", "),
      });
      errorToast(t, message, true);
      return;
    }

    if (files && files.length) {
      onUpload(files);
    }

    e.target.value = "";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // this is required to convert FileList object to array
    const files = [...e.dataTransfer.files];

    // check if the provided count prop is less than uploaded count of files
    if (count && count < files.length) {
      const plural = count !== 1 ? "s" : "";
      const message = t("files.allowed_number", { count, plural });
      errorToast(t, message, true);
      return;
    }

    // check if some uploaded file is not in one of the allowed formats
    if (
      formats &&
      files.some(
        (file) =>
          !formats.some((format) =>
            file.name.toLowerCase().endsWith(format.toLowerCase())
          )
      )
    ) {
      const message = t("files.allowed_formats", {
        formats: formats.join(", "),
      });
      errorToast(t, message, true);
      return;
    }

    if (files && files.length) {
      onUpload(files);
    }
  };

  useEffect(() => {
    if (drop.current) {
      drop.current.addEventListener("dragover", handleDragOver);
      drop.current.addEventListener("drop", handleDrop);
    }

    return () => {
      if (drop.current) {
        drop.current.removeEventListener("dragover", handleDragOver);
        drop.current.removeEventListener("drop", handleDrop);
      }
    };
  }, [drop, handleDragOver, handleDrop]);

  return (
    <div
      ref={drop}
      className="w-100 d-flex align-items-center justify-content-center"
      style={{
        // width: "100%",
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        height: 100,
        padding: 10,
        flexFlow: "column nowrap",
        fontSize: 16,
        color: "#555555",
        border: "2px #e0e0e0 dashed",
        borderRadius: 5,
        // marginTop: ".5rem",
      }}
    >
      {loading ? (
        <LoadingSpinner status={true} />
      ) : (
        <>
          <span style={{ fontSize: 11, fontWeight: 500 }}>
            {t("pagebuilder_upload_text")}
          </span>
          <span className="w-100 text-center">
            <label htmlFor={`upload-photo-${id}`} className="cursor-pointer">
              <UploadIcon className="mt-3" />
            </label>
            <br />
            <input
              type="file"
              id={`upload-photo-${id}`}
              style={{
                top: 0,
                left: 0,
                opacity: 0,
                position: "absolute",
                zIndex: -1,
              }}
              accept={formats.map((x) => "image/" + x).join(",")}
              onChange={handleDrop2}
              multiple
            />
          </span>
        </>
      )}
    </div>
  );
}
