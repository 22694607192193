import { convertBase64 } from "../utils/Functions";
import { useState } from "react";
import FilesDragAndDrop from "./FilesDragAndDrop";
import PropTypes from "prop-types";
import axios from "../utils/CustomAxios";
import { errorToast, successToast } from "../utils/toastNotification";
import { useTranslation } from "react-i18next";

FilesDragAndDropUploader.propTypes = {
  type: PropTypes.oneOf([
    "product",
    "homepage",
    "brand",
    "category",
    "page",
    "shipping-method",
    "logo",
    "favicon",
  ]),
  onUploaded: PropTypes.func,
};

export default function FilesDragAndDropUploader({
  type,
  count = 1,
  onUploaded,
  formats = ["jpg", "jpeg", "png"],
}) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("common");

  const uploadFile = (base64, realName) => {
    setLoading(true);
    axios
      .post("v1/files", { type: type, image: base64, realName: realName })
      .then((res) => {
        if (res.data.status === 1) {
          onUploaded(res.data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          errorToast(t, "notUploaded");
        }

        setLoading(false);
      });
  };

  const onChangeImage = async (files) => {
    // let temp = [];

    for (let i = 0; i < files.length; i++) {
      let base64 = await convertBase64(files[i]);

      uploadFile(base64, files[i].name);
    }
  };

  return (
    <FilesDragAndDrop
      count={count}
      formats={formats}
      loading={loading}
      onUpload={(files) => onChangeImage(files)}
    />
  );
}
