import { memo, useEffect, useState } from "react";
import { LoadingIcon, XIcon } from "./Icons";
import { useTranslation } from "react-i18next";

function MultiSelect({
  options,
  optionKey = "id",
  optionValueKey = "name",
  values = [],
  onChange,
  error,
  touched,
  onBlur,
  loading = false,
}) {
  const { t } = useTranslation("common");

  const handleBlur = () => {
    if (typeof onBlur === "function") {
      onBlur();
    }
  };

  const [selectedValues, setSelectedValues] = useState([]);

  const onChangeSelect = (e) => {
    if (typeof onChange === "function") {
      let newVal = parseInt(e.target.value);
      if (values.includes(newVal)) {
        onChange(values.filter((x) => x !== newVal));
      } else {
        onChange([...values, newVal]);
      }
    }
    e.target.value = 0;
  };

  useEffect(() => {
    setSelectedValues(
      options.filter((x) => values.some((y) => x[optionKey] === y))
    );
  }, [options, values]);

  return (
    <>
      <div className="row">
        <div className="col-12 position-relative">
          <select
            className={`form-control position-relative ${
              touched
                ? error
                  ? "is-invalid is-invalid-lite"
                  : "is-valid is-valid-lite"
                : ""
            }`}
            onChange={onChangeSelect}
            onBlur={handleBlur}
          >
            <option value={0}>{t("options.select")}</option>
            {options.map((item, key) => {
              return (
                <option value={item[optionKey]} key={key}>
                  {item[optionValueKey]}
                </option>
              );
            })}
          </select>

          {loading && (
            <span
              style={{ top: "7.5px", right: "15px" }}
              className="position-absolute"
            >
              <LoadingIcon className="text-muted" />
            </span>
          )}
          {touched && error ? (
            <div className="invalid-feedback">{error}</div>
          ) : null}
        </div>

        {selectedValues.length > 0 && (
          <div className="col-12 ps-2 p-1">
            {selectedValues.map((item, key) => {
              return (
                <div
                  key={key}
                  className="border rounded d-inline-block py-1 px-2 bg-light text-dark me-1 mt-1"
                >
                  <div className="row">
                    <div className="col">{item[optionValueKey]}</div>
                    <div
                      className="col-auto ps-0 pe-2"
                      onClick={() =>
                        onChangeSelect({ target: { value: item[optionKey] } })
                      }
                    >
                      <XIcon />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default memo(MultiSelect);
