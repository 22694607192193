import React, { StrictMode } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import MessageManager from "./components/MessageManager";
import { store } from "./redux/store";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./i18n/index";
import "./App.css";

import 'react-toastify/dist/ReactToastify.css';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  // <StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <MessageManager />
  </Provider>
  // </StrictMode>
);
