import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import locale_tr from "./translations/tr/translation.json";
import locale_en from "./translations/en/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        common: locale_en,
      },
      tr: {
        common: locale_tr,
      },
    },
    // lng: "tr", // if you're using a language detector, do not define the lng option
    fallbackLng: "tr",
    debug: false,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
