import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/CustomAxios";

const initialState = {
  summaryProducts: {
    isLoad: false,
    data: null,
    updatedAt: new Date().toISOString(),
  },
  summaryOrders: {
    isLoad: false,
    data: null,
    updatedAt: new Date().toISOString(),
  },
  summaryUsers: {
    isLoad: false,
    data: null,
    updatedAt: new Date().toISOString(),
  },
  summaryCiro: {
    isLoad: false,
    data: null,
    updatedAt: new Date().toISOString(),
  },
  summarySize: {
    isLoad: false,
    data: {
      used_size: 0,
      allowed_size: 0,
    },
    updatedAt: new Date().toISOString(),
  },
  changeLogs: {
    isLoad: false,
    data: null,
    updatedAt: new Date().toISOString(),
  },
};

export const getSummary = createAsyncThunk(
  "dashboard",
  async (args) => {
    let apiURL = null;

    if (args.where === "summaryProducts") {
      apiURL = "v1/panel/dashboard/1";
    } else if (args.where === "summaryOrders") {
      apiURL = "v1/panel/dashboard/2";
    } else if (args.where === "summaryUsers") {
      apiURL = "v1/panel/dashboard/3";
    } else if (args.where === "summaryCiro") {
      apiURL = "v1/panel/dashboard/4";
    } else if (args.where === "summarySize") {
      apiURL = "v1/panel/dashboard/5";
    } else if (args.where === "changeLogs") {
      apiURL = "v1/panel/changelogs";
    }

    let result = await axios.get(apiURL);
    return { where: args.where, data: result.data.data };
  },
  {
    condition: (args, { getState }) => {
      let states = getState();

      if (args.type === 1) {
        return true;
      }

      if (states.homepage[args.where].isLoad === true) {
        return false;
      }
    },
  }
);

export const homepageSlice = createSlice({
  name: "homepage",
  initialState: initialState,
  reducers: {
    setSummaryProducts: (state, value) => {
      state.summaryProducts = value.payload;
    },
    setSummaryOrders: (state, value) => {
      state.summaryOrders = value.payload;
    },
    setSummaryUsers: (state, value) => {
      state.summaryUsers = value.payload;
    },
    setSummaryCiro: (state, value) => {
      state.summaryCiro = value.payload;
    },
    setSummarySize: (state, value) => {
      state.summarySize = value.payload;
    },
    setChangelogs: (state, value) => {
      state.changeLogs = value.payload;
    },
  },
  extraReducers: {
    [getSummary.pending]: (state, action) => {
      state[action.meta.arg.where].isLoad = false;
    },
    [getSummary.fulfilled]: (state, action) => {
      state[action.payload.where].isLoad = true;
      state[action.payload.where].data = action.payload.data;
      state[action.payload.where].updatedAt = new Date().toISOString();
    },
  },
});

export const {
  setSummaryProducts,
  setSummaryOrders,
  setSummaryUsers,
  setSummaryCiro,
  setSummarySize,
} = homepageSlice.actions;

export default homepageSlice.reducer;
