import axios from "axios";

const BASE_URL = "https://pazaryeri.myshopiget.com/api/trendyol";

const TrendyolAPI = {
  login: async (email, password) => {
    try {
      const params = { email, password };
      const response = await axios.post(
        `https://pazaryeri.myshopiget.com/api/login`,
        params
      );
      return response.data.access_token;
    } catch (error) {
      console.error("Login API isteğinde hata:", error);
    }
  },

  getOrders: async (
    token,
    baseKey,
    supplierId,
    page = null,
    limit = null,
    filters = {}
  ) => {
    const validFilters = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => value !== null)
    );

    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: { baseKey, supplierId, page, limit, ...validFilters },
    };

    try {
      const response = await axios.get(`${BASE_URL}/getOrders`, config);
      return JSON.parse(response.data);
    } catch (error) {
      console.error("getOrders API isteğinde hata:", error);
    }
  },

  getBrands: async (name) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/getBrands/byName?name=${encodeURIComponent(name)}`
      );

      return response.data;
    } catch (error) {
      console.error("getBrands API isteğinde hata:", error);
    }
  },

  getCategories: async (name) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/getCategories?name=${encodeURIComponent(name)}`
      );
      return response.data;
    } catch (error) {
      console.error("getCategories API isteğinde hata:", error);
    }
  },

  getCategoryAttributes: async (categoryId) => {
    try {
      const response = await axios.get(`${BASE_URL}/getCategoryAttributes`, {
        params: { categoryId: categoryId },
      });

      if (response.status === 200) {
        console.log("Kategori özellikleri başarıyla alındı:", response.data);
        return response.data;
      } else {
        console.error("Kategori özelliklerini alma hatası:", response.data);
        return null;
      }
    } catch (error) {
      console.error("getCategoryAttributes API isteğinde hata:", error);
      return null;
    }
  },

  createProducts: async (baseKey, supplierId, items) => {
    try {
      const response = await axios.post(`${BASE_URL}/createProducts`, {
        baseKey: baseKey,
        supplierId: supplierId,
        items: items,
      });

      if (response.status === 200) {
        console.log("Ürünler başarıyla aktarıldı:", response.data);
        return response.data;
      } else {
        console.error("Ürün aktarma hatası:", response.data);
        return null;
      }
    } catch (error) {
      console.error("createProducts API isteğinde hata:", error);
      return null;
    }
  },

  filterProducts: async (params) => {
    try {
      const response = await axios.get(`${BASE_URL}/filterProducts`, {
        params,
      });
      return response.data;
    } catch (error) {
      console.error("filterProducts API isteğinde hata:", error);
    }
  },

  // getSuppliers: async (token) => {
  //   const headers = { Authorization: `Bearer ${token}` };
  //   try {
  //     const response = await axios.get(`${BASE_URL}/getSuppliers`, {
  //       headers,
  //     });
  //     return response.data;
  //   } catch (error) {
  //     console.error("getSuppliers API isteğinde hata:", error);
  //   }
  // },

  // createOrder: async (token, orderData) => {
  //   const headers = { Authorization: `Bearer ${token}` };
  //   try {
  //     const response = await axios.post(
  //       `${BASE_URL}/createOrder`,
  //       orderData,
  //       { headers }
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("createOrder API isteğinde hata:", error);
  //   }
  // },

  // getProduct: async (token, productId) => {
  //   const headers = { Authorization: `Bearer ${token}` };
  //   try {
  //     const response = await axios.get(
  //       `${BASE_URL}/getProduct/${productId}`,
  //       { headers }
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("getProduct API isteğinde hata:", error);
  //   }
  // },

  // updateProductStock: async (token, productId, stockData) => {
  //   const headers = { Authorization: `Bearer ${token}` };
  //   try {
  //     const response = await axios.put(
  //       `${BASE_URL}/updateProductStock/${productId}`,
  //       stockData,
  //       { headers }
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("updateProductStock API isteğinde hata:", error);
  //   }
  // },

  // getShipmentStatus: async (token, orderId) => {
  //   const headers = { Authorization: `Bearer ${token}` };
  //   try {
  //     const response = await axios.get(
  //       `${BASE_URL}/getShipmentStatus/${orderId}`,
  //       { headers }
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("getShipmentStatus API isteğinde hata:", error);
  //   }
  // },

  // cancelOrder: async (token, orderId) => {
  //   const headers = { Authorization: `Bearer ${token}` };
  //   try {
  //     const response = await axios.delete(
  //       `${BASE_URL}/cancelOrder/${orderId}`,
  //       { headers }
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("cancelOrder API isteğinde hata:", error);
  //   }
  // },

  // getCategories: async (token) => {
  //   const headers = { Authorization: `Bearer ${token}` };
  //   try {
  //     const response = await axios.get(`${BASE_URL}/getCategories`, {
  //       headers,
  //     });
  //     return response.data;
  //   } catch (error) {
  //     console.error("getCategories API isteğinde hata:", error);
  //   }
  // },

  // getProductDetails: async (token, productId) => {
  //   const headers = { Authorization: `Bearer ${token}` };
  //   try {
  //     const response = await axios.get(
  //       `${BASE_URL}/getProductDetails/${productId}`,
  //       { headers }
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("getProductDetails API isteğinde hata:", error);
  //   }
  // },

  // updateProductPrice: async (token, productId, priceData) => {
  //   const headers = { Authorization: `Bearer ${token}` };
  //   try {
  //     const response = await axios.put(
  //       `${BASE_URL}/updateProductPrice/${productId}`,
  //       priceData,
  //       { headers }
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("updateProductPrice API isteğinde hata:", error);
  //   }
  // },
};

export default TrendyolAPI;
