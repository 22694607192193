import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "../../utils/CustomAxios";

export const NotificationMenu = ({ setVisibleIndex }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const [notificationData, setNotificationData] = useState({
    notifications: [],
    totalCount: 0,
    feedbackCount: 0,
    questionCount: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get("v1/panel/notifications");
        if (response.data.status === 1) {
          setNotificationData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  return (
    <div
      className="dropdown-menu dropdown-menu-end dropdown-menu-arrow show"
      data-bs-popper="none"
    >
      <div className="list-group list-group-flush list-group-hoverable">
        <h6 className="dropdown-header">{t("notifications_")}</h6>
        {loading && (
          <>
            <div className="dropdown-item text-center">{t("loading")}</div>
          </>
        )}
        {!loading && notificationData.questionCount > 0 && (
          <>
            <div
              className="dropdown-item cursor-pointer d-flex justify-content-between align-items-center"
              onClick={() => {
                navigate("/user-questions");
                setVisibleIndex(0);
              }}
            >
              <span className="text-body d-block">{t("new_question")}</span>
              <span className="badge bg-red-lt">
                {notificationData.questionCount}
              </span>
            </div>
            <div className="dropdown-divider"></div>
          </>
        )}
        {!loading && notificationData.feedbackCount > 0 && (
          <>
            <div
              className="dropdown-item cursor-pointer d-flex justify-content-between align-items-center"
              onClick={() => {
                navigate("/user-feedbacks");
                setVisibleIndex(0);
              }}
            >
              <span className="text-body d-block">{t("new_feedback")}</span>
              <span className="badge bg-red-lt">
                {notificationData.feedbackCount}
              </span>
            </div>
          </>
        )}
        {!loading &&
          notificationData.questionCount === 0 &&
          notificationData.feedbackCount === 0 && (
            <>
              <div className="dropdown-item text-center">
                {t("no_notification")}
              </div>
            </>
          )}
      </div>
    </div>
  );
};
