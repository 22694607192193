import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserData } from "../../redux/slices/userSlice";

export const AccountMenu = ({ setVisibleIndex, languages }) => {
  const { i18n } = useTranslation("common");
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const changeLanguage = (item) => {
    localStorage.setItem("language_id", item.id);
    localStorage.setItem("lang_id", item.language_id);
    i18n.changeLanguage(item.code);
    setVisibleIndex(0);
  };

  const logout = () => {
    setVisibleIndex(0);
    localStorage.removeItem("token");
    localStorage.removeItem("shop_id");
    dispatch(
      setUserData({ isLoad: false, isLogin: false, data: { permissions: [] } })
    );
    navigate("/auth/login");
  };

  return (
    <div
      className="dropdown-menu dropdown-menu-end dropdown-menu-arrow show"
      data-bs-popper="none"
    >
      <div className="list-group list-group-flush list-group-hoverable">
        <h6 className="dropdown-header">{t("languages_")}</h6>
        {languages.map((item, key) => (
          <div
            key={key}
            className="dropdown-item"
            onClick={() => changeLanguage(item)}
          >
            <span className="text-body d-block">
              {item.name.split(" - ")[0]}
            </span>
          </div>
        ))}
        <div className="dropdown-divider"></div>
        <h6 className="dropdown-header">{t("account_")}</h6>
        <Link
          to="/account"
          className="dropdown-item"
          onClick={() => setVisibleIndex(0)}
        >
          {t("title_settings")}
        </Link>
        <span onClick={logout} className="dropdown-item">
          {t("logout")}
        </span>
      </div>
    </div>
  );
};
