import { configureStore } from '@reduxjs/toolkit'
import userSlice from './slices/userSlice'
import messageSlice from './slices/messageSlice'
import shopSlice from './slices/shopSlice'
import homepageSlice from './slices/homepageSlice'
import pageBuilderSlice from './slices/pageBuilderSlice'
import barcodeSlice from './slices/barcodeSlice'

export const store = configureStore({
  reducer: {
    user: userSlice,
    message: messageSlice,
    shop: shopSlice,
    homepage: homepageSlice,
    pagebuilder: pageBuilderSlice,
    barcodebuilder: barcodeSlice,
  },
})
