import axios from "../utils/CustomAxios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUserData } from "../redux/slices/userSlice";
import { setShopDetail } from "../redux/slices/shopSlice";
// import { useNavigate } from "react-router-dom";

export default function useCheckLogin() {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkLogin = () => {
      const token = localStorage.getItem("token");

      if (token === null) {
        dispatch(
          setUserData({
            isLoad: true,
            isLogin: false,
            data: {
              permissions: [],
            },
          })
        );
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        axios
          .get("/v1/auth/me")
          .then((res) => {
            dispatch(
              setUserData({
                isLoad: true,
                isLogin: res.data.status === 1,
                data:
                  res.data.status === 1
                    ? res.data.data.user
                    : {
                        permissions: [],
                      },
              })
            );
            dispatch(setShopDetail(res.data.data.shop));
            localStorage.setItem(
              "lang_id",
              res?.data?.data?.shop?.default_language_id ?? 175
            );

            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            dispatch(
              setUserData({
                isLoad: true,
                isLogin: false,
                data: {
                  permissions: [],
                },
              })
            );
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          });
      }
    };

    checkLogin();
  }, []);

  return { loading: loading };
}
