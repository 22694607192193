import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageAPI from "../../api/LanguageAPI";
import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "../Button";
import {
  convertBase64,
  findParentsTree,
  generateTree,
} from "../../utils/Functions";
import Select from "react-select";
import axios from "../../utils/CustomAxios";
import { useSelector } from "react-redux";
import CountryCityDistrictAPI from "../../api/CountryCityDistrictAPI";
import ProductAPI from "../../api/ProductAPI";
import { errorToast, successToast } from "../../utils/toastNotification";
import { LoadingIcon } from "../Icons";
import CategoryAPI from "../../api/CategoryAPI";
import TagAPI from "../../api/TagAPI";
import MultiSelect from "../MultiSelect";
import ProductSortableImage from "../../pages/product/ProductSortableImage";
import FilesDragAndDropUploader from "../FilesDragAndDropUploader";
import MarketplacesAPI from "../../api/MarketplacesAPI";
import TrendyolAPI from "../../api/TrendyolAPI";
import PSIMarketplacesDirectLink from "../../pages/product/PSIMarketplacesDirectLink";
import { GiCheckMark } from "react-icons/gi";

const FastProductForm = ({
  setVisible,
  name,
  quantity,
  isSelfSubmitting = true,
  onSuccess = () => {},
  formikRef = null,
  isPage = false,
  selectedItem = null,
}) => {
  const { t } = useTranslation("common");
  const { shopDetail } = useSelector((state) => state.shop);
  const defaultSettings = shopDetail?.fast_product_settings;

  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const [imported, setImported] = useState(false);

  const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(0);
  const [categories, setCategories] = useState([]);
  const [typeID, setTypeID] = useState(1);
  const [attributes, setAttributes] = useState([]);
  const [taxID, setTaxID] = useState(0);
  const [countries, setCountries] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tags, setTags] = useState([]);
  const [units, setUnits] = useState([]);
  const [autoMETA, setAutoMETA] = useState(true);
  const [imageCounter, setImageCounter] = useState(0);
  const [marketPlaces, setMarketPlaces] = useState([]);
  const [marketplaceBrand, setMarketplaceBrand] = useState(null);
  const [marketplaceImages, setMarketplaceImages] = useState([]);

  const [taxModalOpen, setTaxModalOpen] = useState(false);

  const [priceInputTouched, setPriceInputTouched] = useState(false);

  //Variation
  const [selectedOptionIndexesID, setSelectedOptionIndexesID] = useState([]);

  const validationSchema = Yup.object().shape({
    typeID: Yup.number()
      .notOneOf([0, 2], t("validation.type"))
      .required(t("validation.type")),
    mainProductCategoryID: Yup.object().shape({
      value: Yup.number()
        .notOneOf([0], t("validation.main_category"))
        .required(t("validation.main_category")),
      label: Yup.string().required(t("validation.main_category")),
    }),
    taxID: Yup.number()
      .notOneOf([0], t("validation.tax"))
      .required(t("validation.tax")),
    minQuantity: Yup.number()
      .min(1, t("validation.min"))
      .test("min-is-more-than-max", t("validation.min-max"), function (value) {
        const maxQuantity = this.parent.maxQuantity;
        return value <= maxQuantity;
      })
      .test("modulus-check", t("validation.min-mod"), function (value) {
        const stepQuantity = this.parent.stepQuantity;
        return value % stepQuantity === 0;
      }),
    maxQuantity: Yup.number()
      .min(1, t("validation.max"))
      .test("max-is-less-than-min", t("validation.max-min"), function (value) {
        const minQuantity = this.parent.minQuantity;
        return value >= minQuantity;
      }),
    stepQuantity: Yup.number().min(1, t("validation.max-mod")),
    translations: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t("validation.translation")),
      })
    ),
    variants: Yup.array()
      .test(
        "options-array-required",
        t("validation.options-required"),
        function (value) {
          const typeID = this.resolve(Yup.ref("typeID")); // Get the current typeID

          if (typeID === 2) {
            // Her bir variant'ın içindeki options dizisini kontrol eder
            return value.every((variant) => {
              return variant.options && variant.options.length > 0;
            });
          }
          return true;
        }
      )
      .test(
        "variants-options-value-id-required",
        t("validation.variants-options-value-id-required"),
        function (value) {
          const typeID = this.resolve(Yup.ref("typeID")); // Get the current typeID

          if (typeID === 2) {
            return value.every((variant) => {
              return variant.options.every(
                (option) => option.option_value_id !== 0
              );
            });
          }
          return true;
        }
      )
      .test(
        "variants-price-required",
        t("validation.variants-price-required"),
        function (value) {
          const typeID = this.resolve(Yup.ref("typeID")); // Get the current typeID

          if (typeID === 3) return true;

          return value.every((variant) => {
            return variant.prices.every(
              (item) => item.price !== "" && item.price > 0
            );
          });
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      typeID: defaultSettings?.typeID ?? 1,
      mainProductCategoryID: { value: 0, label: t("options.select") },
      selectedCategoriesID: [],
      taxID: defaultSettings?.tax ?? 0,
      unitID: defaultSettings?.unit ?? null,
      brandID: defaultSettings?.brand ?? null,
      selectedTagsID: defaultSettings?.tags ?? [],
      supplierID: defaultSettings?.supplier ?? null,
      minQuantity: defaultSettings?.min_quantity ?? 1,
      maxQuantity: defaultSettings?.max_quantity ?? 999,
      stepQuantity: defaultSettings?.step_quantity ?? 1,
      reviewStatus: defaultSettings?.review_status ?? false,
      refundStatus: defaultSettings?.refund_status ?? false,
      adultStatus: defaultSettings?.adult_status ?? false,
      status: defaultSettings?.status ?? true,
      translations: [],
      variants: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (typeID !== 2) {
        formik.values.variants.forEach((el) => {
          el.options = [];
        });
      }

      if (values.selectedCategoriesID.length === 0) {
        values.selectedCategoriesID.push(+values.mainProductCategoryID);
      }
      addData(values);
    },
  });

  const categoriesTree = useMemo(
    () => generateTree(categories, null),
    [categories]
  );

  const recursiveOptions = (categories, prefix = "") => {
    let options = [];

    categories.forEach((category) => {
      options.push({
        value: category.id,
        label: prefix + category.name,
        isDisabled: category.children.length > 0,
      });

      if (category.children && category.children.length) {
        options = options.concat(
          recursiveOptions(category.children, prefix + "- ")
        ); // Alt kategorilere recursive olarak devam ediyoruz.
      }
    });

    return options;
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor:
        formik.touched.mainProductCategoryID &&
        formik.errors.mainProductCategoryID
          ? "#d63939" // hata durumu için renk
          : formik.touched.mainProductCategoryID
          ? "#2fb344" // başarılı durum için renk
          : provided.borderColor, // varsayılan renk
      // burada diğer stiller eklenebilir
    }),
    // Diğer react-select bileşen parçaları için de stiller eklenebilir, örneğin: valueContainer, singleValue, menuList, vs.
  };

  const getCategories = () => {
    return CategoryAPI.getAll({
      attributes: true,
    });
  };

  const getTaxes = () => {
    return axios.get("v1/panel/taxes", {
      params: {
        page: 0,
        limit: 100,
        order_index: "taxes.id",
        order_direction: "desc",
      },
    });
  };

  const getSuppliers = () => {
    return axios.get("v1/panel/suppliers", {
      params: {
        page: 0,
        limit: 100,
        order_index: "suppliers.id",
        order_direction: "desc",
      },
    });
  };

  const getBrands = () => {
    return axios.get("v1/panel/brands", {
      params: {
        page: 0,
        limit: 100,
        order_index: "brands.id",
        order_direction: "desc",
      },
    });
  };

  const getUnits = () => {
    return axios.get("v1/panel/units", {
      params: {
        page: 0,
        limit: 100,
        order_index: "units.id",
        order_direction: "desc",
      },
    });
  };

  const getDatas = () => {
    setDataLoading(true);
    Promise.all([
      LanguageAPI.getAll(),
      getCategories(),
      CountryCityDistrictAPI.getCountries(),
      getTaxes(),
      TagAPI.getAll(),
      getSuppliers(),
      getBrands(),
      getUnits(),
      MarketplacesAPI.getAll(),
    ])
      .then(([res1, res2, res3, res4, res5, res6, res7, res8, res9]) => {
        if (res1.data.status === 1) {
          formik.setFieldValue(
            "translations",
            res1.data.data.map((x, _index) => {
              //mağazanın varsayılan dilini seçili hale getirme
              if (x.id === shopDetail.default_language_id) {
                setSelectedLanguageIndex(_index);
              }

              return {
                id: 0,
                language_name: x.name,
                language_id: x.id,
                name: name || "",
                description: "",
                short_description: "",
                meta_title: "",
                meta_description: "",
                meta_keywords: "",
                auto_meta_keywords: [],
              };
            })
          );
          if (res1.data.data.length && res1.data.data.length === 1) {
            setSelectedLanguageIndex(0);
          }
        }

        if (res2.data.status === 1) {
          let sortedCategories = res2.data.data.categories.sort((a, b) => {
            a = a.name || "";
            b = b.name || "";
            return a.localeCompare(b);
          });
          setCategories(sortedCategories);
        }

        setMarketPlaces([
          ...(res9?.data?.data?.marketplaces ?? []).filter(
            (p) =>
              p?.status === true &&
              p?.auth_data?.baseKey?.length > 0 &&
              `${p?.auth_data?.supplier_id ?? ""}`.length > 0
          ),
        ]);

        if (res3.data.status === 1) {
          setCountries(res3.data.data);
          addOptionCombination(
            res3.data.data,
            (res9?.data?.data?.marketplaces ?? []).filter(
              (p) =>
                p?.status === true &&
                p?.auth_data?.baseKey?.length > 0 &&
                `${p?.auth_data?.supplier_id ?? ""}`.length > 0
            )
          );
        }

        if (res4.data.status === 1) {
          const defTaxExist = res4.data.data.taxes
            .map((t) => t.id)
            .includes(parseInt(`${defaultSettings?.tax}`));
          if (!defTaxExist) {
            formik.setFieldValue("taxID", null);
          }
          setTaxes(res4.data.data.taxes);
        }

        if (res5.data.status === 1) {
          const filteredTags =
            defaultSettings?.tags?.filter((b) =>
              res5.data.data.tags
                .map((t) => t.id)
                .includes(parseInt(`${defaultSettings?.supplier}`))
            ) ?? [];

          formik.setFieldValue("tags", filteredTags);

          setTags(res5.data.data.tags);
        }

        if (res6.data.status === 1) {
          const defSuppExist = res6.data.data.suppliers
            .map((s) => s.id)
            .includes(parseInt(`${defaultSettings?.supplier}`));
          if (!defSuppExist) {
            formik.setFieldValue("supplierID", null);
          }
          setSuppliers(res6.data.data.suppliers);
        }

        if (res7.data.status === 1) {
          const defBrandExist = res7.data.data.brands
            .map((b) => b.id)
            .includes(parseInt(`${defaultSettings?.brand}`));
          if (!defBrandExist) {
            formik.setFieldValue("brandID", null);
          }
          setBrands(res7.data.data.brands);
        }

        if (res8.data.status === 1) {
          const units = res8.data.data.unit
            .map((u) => u.id)
            .includes(parseInt(`${defaultSettings?.unit}`));
          if (!units) {
            formik.setFieldValue("unitID", null);
          }
          setUnits(res8.data.data.units);
        }

        setDataLoading(false);
      })
      .then(() => {
        formik.setTouched("translations");
      })
      .catch(() => {
        setDataLoading(false);
      });
  };

  const getProduct = async () => {
    if (!selectedItem) return;

    const baseKey =
      marketPlaces.find((mp) => mp.id === 2)?.auth_data?.baseKey ?? null;
    const supplierId =
      marketPlaces.find((mp) => mp.id === 2)?.auth_data?.supplier_id ?? null;

    if (!baseKey || !supplierId || !selectedItem.product_marketplace_barcode)
      return;

    const params = {
      baseKey: baseKey,
      supplierId: supplierId,
      barcode: selectedItem.product_marketplace_barcode,
    };

    try {
      const product = await TrendyolAPI.filterProducts(params);

      setMarketplaceImages(product.content[0]?.images);
      setMarketplaceBrand(product.content[0]?.brand ?? null);
      setImported(true);

      const trendyolBrand = brands.filter((brand) => {
        const marketplaces = brand.marketplaces ?? [];
        if (!marketplaces.length) {
          return false;
        }
        return marketplaces.some(
          (m) => m.marketplace_id === 2 && m.name === product.content[0]?.brand
        );
      });

      if (trendyolBrand.length > 0) {
        formik.setFieldValue("brandID", trendyolBrand[0].id);
      }

      const trendyolCategory = categories.filter((category) => {
        const marketplaces = category.marketplaces ?? [];

        if (!marketplaces.length) {
          return false;
        }

        return marketplaces.some((m) => {
          const split = m.name.split(">");
          const lastCategory = split[split.length - 1].trim();
          return (
            m.marketplace_id === 2 &&
            lastCategory === product.content[0]?.categoryName
          );
        });
      });

      if (trendyolCategory.length > 0) {
        formik.setFieldValue("mainProductCategoryID", {
          value: trendyolCategory[0].id,
          label: trendyolCategory[0].name,
        });

        onChangeMainCategoryID(trendyolCategory[0].id);
      }
    } catch (error) {}
  };

  const brandExist = (name) => {
    if (!name) return false;
    if (!marketplaceBrand) return false;
    if (name.trim().length === 0) return false;
    let _name = name.trim();
    const brand = marketplaceBrand.trim();
    const brandRegex = new RegExp(`^${brand}`, "i");

    return brandRegex.test(_name);
  };

  useEffect(() => {
    formik.setFieldValue(
      "translations",
      formik.values.translations.map((translation) =>
        translation.language_id === 175
          ? {
              ...translation,
              name: selectedItem.product_marketplace_name,
            }
          : translation
      )
    );

    formik.setFieldValue(
      "variants",
      formik.values.variants.map((variant, index) => {
        const listing_prices = variant.prices.filter(
          (i) => i.country_id >= 2000
        );
        const _prices = variant.prices.reduce((sum, curr) => {
          const temp = [...sum];
          temp.push(curr);
          if (curr.country_id < 2000 && curr.country_id >= 1000) {
            const isListingExist = listing_prices.find(
              (i) => i.country_id === curr.country_id + 1000
            );
            if (!isListingExist) {
              temp.push({
                ...curr,
                id: 0,
                country_id: curr.country_id + 1000,
              });
            }
          }

          return temp;
        }, []);
        return index === 0
          ? {
              ...variant,
              prices: _prices.map((price) => {
                if (price.country_id === 225 || price.country_id >= 1000)
                  return {
                    ...price,
                    price:
                      price.country_id > 2000
                        ? selectedItem.product_marketplace_list_price
                        : parseFloat(selectedItem.product_marketplace_price),
                  };
                else return price;
              }),
              images: [
                ...variant.images,
                ...marketplaceImages.map((marketplaceImage, index) => {
                  let type;
                  if (index === 0) {
                    type = 1;
                  } else if (index === 1) {
                    type = 0;
                  } else {
                    type = index + 1;
                  }
                  return {
                    id: 0,
                    type: type,
                    image: marketplaceImage.url,
                    status: true,
                    deleted: false,
                  };
                }),
              ],
            }
          : variant;
      })
    );
  }, [imported]);

  const getLastPartOfUrl = (url) => {
    const extension = url.split(".").pop();
    const extensionless = url.substring(0, url.lastIndexOf("."));
    const parts = extensionless.split("/");
    return (
      parts[parts.length - 2] + "-" + parts[parts.length - 1] + "." + extension
    );
  };

  const fetchImageAsBlob = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    console.log(blob.type, blob.size);

    return blob;
  };

  const uploadImages = async (variants) => {
    await Promise.all(
      variants.map(async (variant) => {
        await Promise.all(
          variant.images.map(async (imageObj, index) => {
            let base64;

            // Eğer URL ise, önce Blob'a dönüştürün
            if (typeof imageObj.image === "string") {
              const imageBlob = await fetchImageAsBlob(imageObj.image);
              base64 = await convertBase64(imageBlob);
            } else if (
              imageObj.image instanceof Blob ||
              imageObj.image instanceof File
            ) {
              base64 = await convertBase64(imageObj.image);
            } else {
              console.error("Image is not a valid Blob, File, or URL");
              return;
            }

            // Her resim için ayrı bir istek gönderiyoruz
            await axios.post("v1/files", {
              type: "product_marketplace",
              image: base64,
              realName: getLastPartOfUrl(imageObj.image),
            });
          })
        );
      })
    );
  };

  const addData = async (model) => {
    setLoading(true);
    await uploadImages(model.variants);

    const processedVariants = model.variants.map((variant) => ({
      ...variant,
      images: variant.images.map((imageObj) => ({
        ...imageObj,
        image: getLastPartOfUrl(imageObj.image),
      })),
    }));

    const processedTranslations = model.translations.map((translation) => ({
      ...translation,
      name: marketplaceBrand
        ? `${marketplaceBrand} ${translation.name}`
        : translation.name,
    }));

    ProductAPI.create({
      product_type_id: model.typeID,
      main_category_id: model.mainProductCategoryID.value,
      categories: model.selectedCategoriesID,
      tax_id: parseInt(model.taxID) > 0 ? model.taxID : null,
      unit_id: parseInt(model.UnitID) > 0 ? model.unitID : null,
      brand_id: parseFloat(model.brandID) > 0 ? model.brandID : null,
      tags: model.tags,
      supplier_id: parseFloat(model.supplierID) > 0 ? model.supplierID : null,
      harmonized_system_code_id: null,
      group_code: null,
      min_quantity: model.minQuantity,
      max_quantity: model.maxQuantity,
      step_quantity: model.stepQuantity,
      preparation_time: 5,
      review_status: model.reviewStatus,
      refund_status: model.refundStatus,
      adult_status: model.adultStatus,
      status: model.status,
      translations: processedTranslations,
      variants: processedVariants,
      selected_options: selectedOptionIndexesID
        .filter((x) => x !== -1)
        .map((x) => options[x].id),
      attributes: attributes
        .filter((x) => x.active)
        .flatMap((x) => x.selected_values),
      auto_meta: autoMETA,
    })
      .then((res) => {
        if (res.data.status === 1) {
          successToast(t, "saved");
          clearStorage();
          setVisible(false);
          onSuccess(res.data.data.variants[0].id);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);

        if (err.response.data.status === 2) {
          errorToast(t, "validation.stock-code-or-barcode-already-exist");
        } else {
          errorToast(t, "notSaved");
        }
        setLoading(false);
      });
  };

  const addOptionCombination = (countries, marketPlaces) => {
    //basit ürünse en fazla 1 adet eklebilir
    if ((typeID === 1 || typeID === 3) && formik.values.variants.length > 0) {
      return;
    }

    // const defaultCurrencyID = currencies[0]["id"];

    formik.setFieldValue("variants", [
      ...formik.values.variants,
      {
        id: 0,
        is_default: formik.values.variants.length > 0 ? false : true,
        quantity: defaultSettings?.stock_quantity ?? 0,
        supplier_price: 0,
        supplier_code: null,
        stock_code: null,
        shelf: null,
        barcode: null,
        width: 0,
        height: 0,
        length: 0,
        weight: 0,
        status: true,
        selected_price_index: 0,
        prices: [
          ...countries.map((x) => {
            return {
              id: 0,
              country_id: x.id,
              country_name: x.name,
              price: 1,
              strikethrough_price: null,
              status: true,
            };
          }),
          ...marketPlaces
            .filter(
              (m) =>
                m?.status === true &&
                m?.auth_data?.baseKey?.length > 0 &&
                `${m?.auth_data?.supplier_id ?? ""}`.length > 0
            )
            .map((x) => {
              return {
                id: 0,
                country_id: 1000 + x.id,
                country_name: x.name,
                price: 1,
                strikethrough_price: null,
                status: true,
              };
            }),
          ...marketPlaces
            .filter(
              (m) =>
                m?.status === true &&
                m?.auth_data?.baseKey?.length > 0 &&
                `${m?.auth_data?.supplier_id ?? ""}`.length > 0
            )
            .map((x) => {
              return {
                id: 0,
                country_id: 2000 + x.id,
                country_name: x.name,
                price: 1,
                strikethrough_price: null,
                status: true,
              };
            }),
        ],
        options: selectedOptionIndexesID
          .filter((x) => x !== -1)
          .map((_index) => ({
            option_id: options[_index].id,
            option_value_id: 0,
          })),
        images: [],
        deleted: false,
      },
    ]);
  };

  const onChangeProductMainCategoryID = (
    _categories,
    _attributes,
    _mainProductCategoryID,
    selected_attributes
  ) => {
    if (
      _attributes.length > 0 &&
      _categories.length > 0 &&
      _mainProductCategoryID !== 0
    ) {
      let selectedCategory = _categories.find(
        (x) => x.id === _mainProductCategoryID
      );

      if (selectedCategory !== undefined) {
        //seçilen ana kategori attribute idleri

        let selectedCategoryAttributes = selectedCategory.attributes;

        let newActiveAttributes = _attributes.map((x) => {
          return {
            ...x,
            active: selectedCategoryAttributes.includes(x.id),
            selected_values: [],
          };
        });

        setAttributes(newActiveAttributes);

        //kategorinin varsayılan kdv türünü seçtirme
        //seçilen ana kategorinin varsayılan kdv türünü seçili hale getirme
        // setTaxID(selectedCategory.default_tax_id);
      }
    }
  };

  const onChangeMainCategoryID = (e) => {
    // const _mainProductCategoryID = parseInt(e.target.value);
    console.log("e", e);

    const _mainProductCategoryID = parseInt(e);
    console.log(_mainProductCategoryID);

    onChangeProductMainCategoryID(
      categories,
      attributes,
      _mainProductCategoryID,
      []
    );

    //seçilen ana kategorinin üst kategorilerini seçili hale getirme
    const parentCategoryIDS = findParentsTree(
      {
        id: 0,
        children: categoriesTree,
      },
      _mainProductCategoryID
    );

    console.log("parentCategoryIDS", parentCategoryIDS);

    formik.setFieldValue("selectedCategoriesID", [
      ...new Set([...parentCategoryIDS]),
    ]);
  };

  const onChangeOptionValue = (optionCombinationIndex, key, value) => {
    let temp = [...formik.values.variants];

    if (key === "is_default") {
      temp = temp.map((item, key) => {
        return {
          ...item,
          is_default: optionCombinationIndex === key ? true : false,
        };
      });
    } else if (key === "options") {
      //aynı varyasyonlar seçilimi onları kontrol etme
      let existSameCombination = temp
        .filter((x) => !x.deleted)
        .filter((x) => !x.options.some((y) => y.option_value_id === 0)) //"seçiniz" olmayanlarda arama
        .findIndex((x) => JSON.stringify(x.options) === JSON.stringify(value));

      if (existSameCombination !== -1) {
        alert(t("same_options_selected"));
      } else {
        temp[optionCombinationIndex][key] = value;
      }
    } else if (key === "deleted") {
      if (confirm(t("delete?"))) {
        //fiyatlandırma en az 1 adet ve varsayılan değilse kaldırma gerçekleştir
        if (
          temp.length > 1 &&
          temp[optionCombinationIndex].is_default === false
        ) {
          temp[optionCombinationIndex].images.forEach((el) => {
            axios
              .get("v1/files/", {
                params: {
                  name: el.image,
                },
              })
              .then((res) => {
                if (res.data.status === 1) {
                  axios.delete("v1/files/" + res.data.data.id).then((res2) => {
                    if (res2.data.status === 1) {
                      // Local storage'dan tüm anahtarlarını getir
                      for (let i = 0; i < localStorage.length; i++) {
                        const key = localStorage.key(i);
                        // Sadece 'image' ile başlayan anahtarlarla ilgileniyoruz
                        if (key.startsWith("image")) {
                          const value = localStorage.getItem(key);
                          // Aradığımız değer
                          if (value === el.image) {
                            localStorage.removeItem(key);
                            break;
                          }
                        }
                      }
                    }
                  });
                }
              });
          });
          temp.splice(optionCombinationIndex, 1);
          successToast(t, "deleted");
        }
      }
    } else {
      temp[optionCombinationIndex][key] = value;
    }
    formik.setFieldValue("variants", temp);
  };

  const onChangeImageValue = (optionCombinationIndex, index, key, value) => {
    let temp = [...formik.values.variants];

    if (key === "deleted") {
      temp[optionCombinationIndex].images.splice(index, 1);
    } else if (key === "type") {
      if (value === 0) {
        temp[optionCombinationIndex].images[index][key] = value;
      } else {
        //ürün resim yüzü normal değilse
        temp[optionCombinationIndex].images = temp[
          optionCombinationIndex
        ].images.map((item2, key2) => {
          if (key2 === index) {
            return { ...item2, type: value };
          } else if (item2.type === value) {
            return { ...item2, type: 0 };
          } else {
            return { ...item2 };
          }
        });
      }
    } else {
      temp[optionCombinationIndex].images[index][key] = value;
    }

    formik.setFieldValue("variants", temp);
  };

  const onChangeOptionValuePrice = (index, key, value) => {
    let temp = [...formik.values.variants];
    temp[index]["prices"][temp[index].selected_price_index][key] = value;
    formik.setFieldValue("variants", temp);
  };

  const onChangeOptionValueListingPrice = (index, listingPrice, value) => {
    let temp = [...formik.values.variants];

    temp[index]["prices"] = temp[index]["prices"].map((p) => {
      if (p.country_id === listingPrice?.country_id) {
        return { ...p, price: value };
      } else {
        return { ...p };
      }
    });

    formik.setFieldValue("variants", temp);
  };

  //seçilen varyasyon değiştiğinde
  const onChangeSelectedOptionIndex = (key, value) => {
    //ilk baştakileri boş bırakamaz

    if (key !== 0 && selectedOptionIndexesID[key - 1] === -1) {
      return alert(t("selection_in_order"));
    }

    // silerken de aynı şekilde
    if (
      key !== selectedOptionIndexesID.length - 1 &&
      selectedOptionIndexesID[key + 1] !== -1
    ) {
      return alert(t("selection_in_order"));
    }

    let _selectedOptionIndexesID = [...selectedOptionIndexesID];
    _selectedOptionIndexesID[key] = value;
    setSelectedOptionIndexesID(_selectedOptionIndexesID);

    //ana varyasyonlar değiştiğinde değeleri sıfırlama işlemi
    let _variants = formik.values.variants.map((x) => {
      return {
        ...x,
        options: _selectedOptionIndexesID
          .filter((y) => y !== -1)
          .map((_index) => ({
            option_id: options[_index].id,
            option_value_id: 0,
          })),
      };
    });

    formik.setFieldValue("variants", _variants);
  };

  const clearStorage = () => {
    for (let i = localStorage.length - 1; i >= 0; i--) {
      const key = localStorage.key(i);
      if (key.startsWith("image")) {
        localStorage.removeItem(key);
      }
    }
  };

  useEffect(() => {
    getDatas();
  }, []);

  useEffect(() => {
    if (formikRef) {
      if (formikRef.current === null) {
        formikRef.current = formik;
      } else return;
    }
  }, [formikRef?.current]);

  // useEffect(() => {
  //   if (countries.length === 0) return;
  //   if (formik.values.variants.length == 0) return;
  //   if (countries.length > 0 && formik.values.variants.length === 0) {
  //     addOptionCombination();
  //   }
  // }, [countries]);

  return (
    <>
      {dataLoading ? (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <LoadingIcon className="text-secondary" />
        </div>
      ) : (
        <>
          <div className="btn-list d-flex justify-content-end mb-3">
            {isSelfSubmitting && selectedItem && (
              <Button
                text={t("import")}
                className="btn-outline-primary"
                onClick={async (e) => {
                  await getProduct();
                }}
              />
            )}
            {isSelfSubmitting && (
              <Button
                text={t("save")}
                loading={loading}
                className="btn-primary"
                onClick={(e) => {
                  setPriceInputTouched(true);
                  formik.submitForm(e);
                }}
              />
            )}
          </div>
          <form onSubmit={formik.handleSubmit} className="row">
            <div className="col-12">
              <div className="form-group row">
                <label className="form-label col-3 col-form-label"></label>
                <div className="col text-end">
                  {marketplaceBrand &&
                  !brandExist(
                    formik.values.translations[selectedLanguageIndex].name
                  ) ? (
                    <span className="text-secondary">{marketplaceBrand}</span>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="col-12 mb-2">
                {(() => {
                  const error =
                    formik.errors?.translations?.[selectedLanguageIndex]?.name;
                  const touched =
                    formik.touched?.translations?.[selectedLanguageIndex]?.name;
                  const value =
                    formik.values?.translations[selectedLanguageIndex]?.name ||
                    "";
                  const isValid = !error && touched && value !== "";

                  return (
                    <div className="form-group row">
                      <label className="form-label col-3 col-form-label required">
                        {imported &&
                          formik.values.translations[selectedLanguageIndex]
                            ?.language_id === 175 && (
                            <GiCheckMark className="text-success me-2" />
                          )}
                        {t("labels.name")}
                      </label>
                      <div className="col">
                        <div className="row">
                          {formik.values.translations.length &&
                          formik.values.translations.length > 1 ? (
                            <div className="col-12 mb-2">
                              <div className="btn-group w-100 overflow-auto">
                                {formik.values.translations.map((item, key) => {
                                  return (
                                    <span
                                      key={"translations--" + key}
                                      onClick={() =>
                                        setSelectedLanguageIndex(key)
                                      }
                                      className={
                                        "btn " +
                                        (selectedLanguageIndex === key
                                          ? "btn-primary"
                                          : "")
                                      }
                                    >
                                      {item.language_name}
                                    </span>
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                          <div className="col-12">
                            <div className="input-group">
                              <input
                                type="text"
                                className={`form-control ${
                                  touched && error
                                    ? "is-invalid is-invalid-lite"
                                    : isValid
                                    ? "is-valid is-valid-lite"
                                    : ""
                                }`}
                                name={`translations[${selectedLanguageIndex}].name`}
                                value={value}
                                onChange={(e) => {
                                  const temp = [...formik.values.translations];
                                  temp[selectedLanguageIndex].name =
                                    e.target.value;
                                  formik.setFieldValue("translations", temp);
                                }}
                                onBlur={formik.handleBlur}
                              />
                            </div>

                            {touched && error && (
                              <div className="invalid-feedback">{error}</div>
                            )}

                            {!error && formik?.errors?.translations ? (
                              <div className="text-info fs-5 mt-2">
                                {t("please-fill-all-the-language-fields")}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })()}
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="form-group row">
                <label className="form-label col-3 col-form-label required">
                  {t("labels.type")}
                </label>
                <div className="col">
                  <select
                    className={`form-control ${
                      formik.touched.typeID
                        ? formik.errors.typeID
                          ? "is-invalid is-invalid-lite"
                          : "is-valid is-valid-lite"
                        : ""
                    }`}
                    id="typeID"
                    name="typeID"
                    onChange={(e) =>
                      formik.setFieldValue("typeID", e.target.value)
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.typeID}
                  >
                    <option value={1}>{t("options.product")}</option>
                    <option value={3}>{t("options.service")}</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              <div className="form-group row">
                <label className="form-label col-3 col-form-label required">
                  {imported &&
                    formik.values.selectedCategoriesID.length > 0 && (
                      <GiCheckMark className="text-success me-2" />
                    )}

                  {t("labels.main_category")}
                </label>
                <div className="col">
                  <Select
                    classNamePrefix="react-select"
                    value={formik.values.mainProductCategoryID}
                    options={recursiveOptions(categoriesTree)}
                    onChange={(option) => {
                      formik.setFieldValue("mainProductCategoryID", option);
                      onChangeMainCategoryID(option.value);
                    }}
                    onBlur={() =>
                      formik.setFieldTouched("mainProductCategoryID")
                    }
                    isSearchable={true}
                    styles={customStyles}
                  />

                  {formik.touched.mainProductCategoryID &&
                  formik.errors.mainProductCategoryID ? (
                    <div
                      style={{
                        color: "#d63939",
                        fontSize: "12px",
                        margin: "4px 0 0",
                      }}
                    >
                      {t(formik.errors.mainProductCategoryID.value)}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="col-12 mb-2">
                <div className="form-group row">
                  <label className="form-label col-3 col-form-label required">
                    {t("labels.tax")}
                  </label>
                  <div className="col">
                    <select
                      className={`form-control ${
                        formik.touched.taxID
                          ? formik.errors.taxID
                            ? "is-invalid is-invalid-lite"
                            : "is-valid is-valid-lite"
                          : ""
                      }`}
                      id="taxID"
                      name="taxID"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.taxID}
                    >
                      <option value={0}>{t("options.select")}</option>
                      {taxes.map((item, key) => {
                        return (
                          <option key={"tax--" + key} value={item.id}>
                            {t(item.name)}
                          </option>
                        );
                      })}
                    </select>

                    {formik.touched.taxID && formik.errors.taxID ? (
                      <div className="invalid-feedback">
                        {t(formik.errors.taxID)}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-12 mb-2">
                {formik.values.variants
                  .filter((x) => !x.deleted)
                  .map((item, key) => (
                    <Fragment key={"stock--" + key}>
                      <div className="form-group row">
                        <label className="form-label col-3 col-form-label required">
                          {t("labels.stock")}
                        </label>
                        <div className="col">
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            value={quantity || item.quantity}
                            onChange={(e) =>
                              onChangeOptionValue(
                                key,
                                "quantity",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </Fragment>
                  ))}
              </div>
              <div className="col-12">
                <div className="form-group row">
                  <label className="form-label col-3 col-form-label">
                    {t("labels.buying_quantity")}
                  </label>
                  <div className="col">
                    <div className="row">
                      <div
                        className={`col-12 ${isPage ? "col-lg-4" : ""} mb-2`}
                      >
                        <div className="input-group">
                          <span
                            className="input-group-text"
                            style={{ width: "130px" }}
                          >
                            {t("labels.min_buying")}
                          </span>
                          <input
                            type="number"
                            pattern="[0-9.]+"
                            className={`form-control`}
                            id="minQuantity"
                            name="minQuantity"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.minQuantity}
                          />
                        </div>
                      </div>
                      <div
                        className={`col-12 ${isPage ? "col-lg-4" : ""} mb-2`}
                      >
                        <div className="input-group">
                          <span
                            className="input-group-text"
                            style={{ width: "130px" }}
                          >
                            {t("labels.max_buying")}
                          </span>
                          <input
                            type="number"
                            pattern="[0-9.]+"
                            className={`form-control`}
                            id="maxQuantity"
                            name="maxQuantity"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.maxQuantity}
                          />
                        </div>
                      </div>
                      <div
                        className={`col-12 ${isPage ? "col-lg-4" : ""} mb-2`}
                      >
                        <div className="input-group">
                          <span
                            className="input-group-text"
                            style={{ width: "130px" }}
                          >
                            {t("labels.increment")}
                          </span>
                          <input
                            type="number"
                            pattern="[0-9.]+"
                            className={`form-control`}
                            id="stepQuantity"
                            name="stepQuantity"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.stepQuantity}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-2">
                {formik.values.variants
                  .filter((x) => !x.deleted)
                  .map((item, key) => (
                    <Fragment key={"price--" + key}>
                      <div className="col-12">
                        <div className=" mb-0">
                          <div className="col-12">
                            <div className="form-group row">
                              <label className="form-label col-3 col-form-label required">
                                {imported && (
                                  <GiCheckMark className="text-success me-2" />
                                )}
                                {t("labels.selling_price")}
                              </label>

                              <div className="col">
                                {item?.prices?.length > 1 && (
                                  <div className="col-12 mb-2">
                                    <div className="btn-group w-100 overflow-auto">
                                      {item.prices
                                        .filter((p) => p.country_id < 2000)
                                        .map((item2, key2) => {
                                          return (
                                            <span
                                              key={"selling-price--" + key2}
                                              onClick={(e) =>
                                                onChangeOptionValue(
                                                  key,
                                                  "selected_price_index",
                                                  key2
                                                )
                                              }
                                              className={
                                                "btn " +
                                                (item.selected_price_index ===
                                                key2
                                                  ? "btn-primary"
                                                  : "")
                                              }
                                            >
                                              {item2.country_name}
                                            </span>
                                          );
                                        })}
                                    </div>
                                  </div>
                                )}

                                <div className="col-12 mb-2">
                                  <div className="input-group">
                                    <span
                                      className="input-group-text"
                                      style={{ width: "130px" }}
                                    >
                                      {`${t("labels.price")} ${
                                        shopDetail.currency_left_symbol || ""
                                      }${
                                        shopDetail.currency_right_symbol || ""
                                      }`}
                                    </span>
                                    {(() => {
                                      const error =
                                        formik.errors?.variants ===
                                        t("validation.variants-price-required");
                                      const value =
                                        item?.prices[item.selected_price_index]
                                          ?.price;
                                      const isValid =
                                        !error &&
                                        priceInputTouched &&
                                        (value !== "" || parseFloat(value) > 0);

                                      if (formik.values.typeID != 3) {
                                        return (
                                          <>
                                            <input
                                              type="number"
                                              className={`form-control ${
                                                priceInputTouched && error
                                                  ? "is-invalid is-invalid-lite"
                                                  : isValid
                                                  ? "is-valid is-valid-lite"
                                                  : ""
                                              }`}
                                              value={
                                                item?.prices[
                                                  item.selected_price_index
                                                ]?.price ?? ""
                                              }
                                              onChange={(e) => {
                                                onChangeOptionValuePrice(
                                                  key,
                                                  "price",
                                                  e.target.value
                                                );
                                              }}
                                              onBlur={() => {
                                                setPriceInputTouched(true);
                                              }}
                                            />

                                            {priceInputTouched && error ? (
                                              <>
                                                {item.prices.length === 1 ? (
                                                  <div className="invalid-feedback">
                                                    {formik.errors?.variants}
                                                  </div>
                                                ) : (
                                                  <div className="invalid-feedback">
                                                    {t(
                                                      "validation.variants-price-required-for-all"
                                                    )}
                                                  </div>
                                                )}
                                              </>
                                            ) : null}
                                          </>
                                        );
                                      } else {
                                        return (
                                          <>
                                            <input
                                              type="number"
                                              className={`form-control`}
                                              value={
                                                item?.prices[
                                                  item.selected_price_index
                                                ]?.price ?? ""
                                              }
                                              onChange={(e) => {
                                                onChangeOptionValuePrice(
                                                  key,
                                                  "price",
                                                  e.target.value
                                                );
                                              }}
                                              onBlur={() => {
                                                setPriceInputTouched(true);
                                              }}
                                            />
                                          </>
                                        );
                                      }
                                    })()}
                                  </div>
                                </div>

                                {item?.prices[item.selected_price_index]
                                  ?.country_id > 1000 &&
                                item?.prices.find(
                                  (el) =>
                                    el.country_id ===
                                    item?.prices[item.selected_price_index]
                                      ?.country_id +
                                      1000
                                ) ? (
                                  <div className="col-12 ">
                                    {(() => {
                                      const country_id =
                                        item?.prices[item.selected_price_index]
                                          ?.country_id;

                                      const listing_price =
                                        item?.prices?.find(
                                          (i) =>
                                            i.country_id === country_id + 1000
                                        ) ?? null;

                                      return (
                                        <>
                                          <div className="input-group">
                                            <span
                                              className="input-group-text"
                                              style={{ width: "130px" }}
                                            >
                                              {`${t("labels.listing_price")} ${
                                                shopDetail.currency_left_symbol ||
                                                ""
                                              }${
                                                shopDetail.currency_right_symbol ||
                                                ""
                                              }`}
                                            </span>

                                            {(() => {
                                              const error =
                                                formik.errors?.variants ===
                                                t(
                                                  "validation.variants-price-required"
                                                );

                                              const value =
                                                listing_price?.price;

                                              const isValid =
                                                !error &&
                                                priceInputTouched &&
                                                (value !== "" ||
                                                  parseFloat(value) > 0);

                                              if (formik.values.typeID != 3) {
                                                return (
                                                  <>
                                                    <input
                                                      type="number"
                                                      className={`form-control ${
                                                        priceInputTouched &&
                                                        error
                                                          ? "is-invalid is-invalid-lite"
                                                          : isValid
                                                          ? "is-valid is-valid-lite"
                                                          : ""
                                                      }`}
                                                      value={
                                                        listing_price?.price ??
                                                        ""
                                                      }
                                                      onChange={(e) => {
                                                        onChangeOptionValueListingPrice(
                                                          key,
                                                          listing_price,
                                                          e.target.value
                                                        );
                                                      }}
                                                      onBlur={() => {
                                                        setPriceInputTouched(
                                                          true
                                                        );
                                                      }}
                                                    />
                                                  </>
                                                );
                                              } else {
                                                return (
                                                  <>
                                                    <input
                                                      type="number"
                                                      className={`form-control`}
                                                      value={
                                                        listing_price?.price ??
                                                        ""
                                                      }
                                                      onChange={(e) => {
                                                        onChangeOptionValueListingPrice(
                                                          key,
                                                          listing_price,
                                                          e.target.value
                                                        );
                                                      }}
                                                      onBlur={() => {
                                                        setPriceInputTouched(
                                                          true
                                                        );
                                                      }}
                                                    />
                                                  </>
                                                );
                                              }
                                            })()}
                                          </div>
                                        </>
                                      );
                                    })()}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ))}
              </div>

              <div className="col-12 mb-2">
                <div className="form-group row">
                  <label className="form-label col-3 col-form-label">
                    {t("labels.unit")}
                  </label>
                  <div className="col">
                    <select
                      className={`form-control ${
                        formik.touched.unitID
                          ? formik.errors.unitID
                            ? "is-invalid is-invalid-lite"
                            : "is-valid is-valid-lite"
                          : ""
                      }`}
                      id="unitID"
                      name="unitID"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.unitID}
                    >
                      <option value={0}>{t("options.select")}</option>
                      {units.map((item, key) => {
                        return (
                          <option value={item.id} key={"unit--" + key}>
                            {t(item.name)}
                          </option>
                        );
                      })}
                    </select>

                    {formik.touched.unitID && formik.errors.unitID ? (
                      <div className="invalid-feedback">
                        {t(formik.errors.unitID)}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-12 mb-2">
                <div className="form-group row">
                  <label className="form-label col-3 col-form-label">
                    {imported && formik.values.brandID && (
                      <GiCheckMark className="text-success me-2" />
                    )}
                    {t("labels.brand_name")}
                  </label>
                  <div className="col">
                    <select
                      className={`form-control ${
                        formik.touched.brandID
                          ? formik.errors.brandID
                            ? "is-invalid is-invalid-lite"
                            : "is-valid is-valid-lite"
                          : ""
                      }`}
                      id="brandID"
                      name="brandID"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.brandID}
                    >
                      <option value={0}>{t("options.select")}</option>
                      {brands.map((item, key) => {
                        return (
                          <option key={"brand--" + key} value={item.id}>
                            {t(item.name)}
                          </option>
                        );
                      })}
                    </select>

                    {formik.touched.brandID && formik.errors.brandID ? (
                      <div className="invalid-feedback">
                        {t(formik.errors.brandID)}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-12 mb-2">
                <div className="form-group row">
                  <label className="form-label col-3 col-form-label">
                    {t("labels.tags")}
                  </label>
                  <div className="col">
                    <MultiSelect
                      optionKey="id"
                      optionValueKey="name"
                      options={tags}
                      values={formik.values.selectedTagsID}
                      onChange={(selectedOptions) => {
                        formik.setFieldValue(
                          "selectedTagsID",
                          selectedOptions.map((option) => option)
                        );
                      }}
                      onBlur={() =>
                        formik.setFieldTouched("selectedTagsID", true)
                      }
                      error={formik.errors.selectedTagsID}
                      touched={formik.touched.selectedTagsID}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 mb-2">
                <div className="form-group row">
                  <label className="form-label col-3 col-form-label">
                    {t("labels.supplier")}
                  </label>
                  <div className="col">
                    <select
                      className={`form-control ${
                        formik.touched.supplierID
                          ? formik.errors.supplierID
                            ? "is-invalid is-invalid-lite"
                            : "is-valid is-valid-lite"
                          : ""
                      }`}
                      id="supplierID"
                      name="supplierID"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.supplierID}
                    >
                      <option value={0}>{t("options.select")}</option>
                      {suppliers.map((item3, key3) => {
                        return (
                          <option value={item3.id} key={"supplier--" + key3}>
                            {item3.name}
                          </option>
                        );
                      })}
                    </select>

                    {formik.touched.supplierID && formik.errors.supplierID ? (
                      <div className="invalid-feedback">
                        {t(formik.errors.supplierID)}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              <div className="form-group row align-items-center">
                <label className="form-label col-3 mb-0 col-form-label">
                  {t("labels.review")}
                </label>
                <div className="col">
                  <label className="form-check form-switch mb-0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="reviewStatus"
                      name="reviewStatus"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.reviewStatus}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              <div className="form-group row align-items-center">
                <label className="form-label mb-0 col-3 col-form-label">
                  {t("labels.refund")}
                </label>
                <div className="col">
                  <label className="form-check form-switch mb-0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="refundStatus"
                      name="refundStatus"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.refundStatus}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              <div className="form-group row align-items-center">
                <label className="form-label mb-0 col-3 col-form-label">
                  {t("labels.adult")}
                </label>
                <div className="col">
                  <label className="form-check form-switch mb-0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="adultStatus"
                      name="adultStatus"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.adultStatus}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              <div className="form-group row align-items-center">
                <label className="form-label mb-0 col-3 col-form-label">
                  {t("labels.status")}
                </label>
                <div className="col">
                  <label className="form-check form-switch mb-0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="status"
                      name="status"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.status}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              {formik.values.variants
                .filter((x) => !x.deleted)
                .map((item, key) => (
                  <Fragment key={"images--" + key}>
                    <div className="col-12 mb-0">
                      <div className="form-group row">
                        <label className="col-3 form-label col-form-label">
                          {imported && (
                            <GiCheckMark className="text-success me-2" />
                          )}
                          {t("product_images")}
                        </label>
                        <div className="col">
                          <fieldset className="form-fieldset">
                            <div className="row">
                              <div className="col-12 mb-2">
                                <FilesDragAndDropUploader
                                  type="product"
                                  count={5}
                                  onUploaded={(file) => {
                                    //ön resim mevcutmu kontrolü
                                    let checkFrontImage =
                                      formik.values.variants[key].images.some(
                                        (x) =>
                                          x.type === 1 && x.deleted === false
                                      );

                                    // yeni resmi arraya dahil etme
                                    onChangeOptionValue(key, "images", [
                                      ...formik.values.variants[key].images,
                                      {
                                        id: 0,
                                        type: checkFrontImage ? 0 : 1,
                                        image: file.name,
                                        url: file.url,
                                        status: true,
                                        deleted: false,
                                      },
                                    ]);

                                    setImageCounter((prevCounter) => {
                                      // Önceki counter değeri ile localStorage güncellemesi
                                      localStorage.setItem(
                                        "image" + key + prevCounter,
                                        file.name
                                      );

                                      // counter'ı 1 artır
                                      return prevCounter + 1;
                                    });
                                  }}
                                />
                              </div>

                              <div className="col-12">
                                <PSIMarketplacesDirectLink
                                  items={item.images.filter(
                                    (x) => x.deleted === false
                                  )}
                                  setItems={(datas) => {
                                    let temp = [...formik.values.variants];
                                    temp[key].images = datas;
                                    formik.setFieldValue("variants", temp);
                                  }}
                                  onChangeImageValue={onChangeImageValue}
                                  optionCombinationIndex={key}
                                  t={t}
                                />
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default FastProductForm;
