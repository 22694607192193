import axios from "../utils/CustomAxios";

const CountryCityDistrictAPI = {

  getCountries: () => {
    return axios.get(process.env.REACT_APP_API_BASE_LINK + '/v1/web/countries');
  },

  getStates: (id) => {
    return axios.get(process.env.REACT_APP_API_BASE_LINK + `/v1/web/states?country_id=${id}`);
  },

  getCities: (id) => {
    return axios.get(process.env.REACT_APP_API_BASE_LINK + `/v1/web/cities?state_id=${id}`);
  },

  getDistricts: (id) => {
    return axios.get(process.env.REACT_APP_API_BASE_LINK + `/v1/web/districts?city_id=${id}`);
  },

  getNeighborhoods: (id) => {
    return axios.get(process.env.REACT_APP_API_BASE_LINK + `/v1/web/neighborhoods?districts_id=${id}`);
  },

  /*getCities: () => {
    return axios.get('https://depoapi.biz.tr/api/listTurkiyeCity');
  },

  getDistricts: (id) => {
    return axios.get(
          `https://depoapi.biz.tr/api/listTurkiyeTownByCityKEY/${id}`);
  },*/
};

export default CountryCityDistrictAPI;
