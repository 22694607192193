import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedTheme: {
    id: null,
    name: null,
    theme_json: {
      value: null,
      attributes: null,
    },
    is_default: null,
    created_at: null,
  },
  barcodeThemes: [],
  barcodeList: [],
  barcodeThemeList: [],
  barcodeAttributes: {
    dpi: 8,
    width_mm: null,
    height_mm: null,
    theme_name: null,
  },
  multipleTag: null,
  tagSize: {
    width_mm: null,
    height_mm: null,
  },
}

export const barcodeSlice = createSlice({
  name: 'barcodebuilder',
  initialState: initialState,
  reducers: {
    setMultipleTag: (state, action) => {
      state.multipleTag = action.payload
    },
    setBarcodeThemes: (state, action) => {
      state.barcodeThemes = action.payload
    },
    setSelectedTheme_: (state, action) => {
      state.selectedTheme = action.payload
    },
    setBarcode: (state, action) => {
      state.barcodeList = action.payload
    },
    removeFromBarcode: (state, action) => {
      state.barcodeList.splice(action.payload, 1)
    },
    setBarcodeList: (state, action) => {
      state.barcodeList = [...state.barcodeList, action.payload]
    },
    setBarcodeAttributes: (state, action) => {
      state.barcodeAttributes = action.payload
    },
    setBarcodeAttributeChanger: (state, action) => {
      const { idx, key, value } = action.payload
      state.barcodeList[idx]['data'][key] = value
    },
    setBarcodeThemeList: (state, action) => {
      const { attributes, value } = action.payload
      state.barcodeThemeList = [...state.barcodeThemeList, { attributes: attributes, value: value }]
    },
    setBarcodeTheme: (state, action) => {
      state.barcodeAttributes = action.payload
    },
    setBarcodeThemeAttributes: (state, action) => {
      const { key, value } = action.payload
      state.barcodeAttributes[key] = value
    },
  },
})

export const {
  setMultipleTag,
  setBarcode,
  setBarcodeList,
  setBarcodeTheme,
  setBarcodeThemes,
  removeFromBarcode,
  setSelectedTheme_,
  setBarcodeAttributeChanger,
  setBarcodeThemeList,
  setBarcodeThemeAttributes,
} = barcodeSlice.actions

export default barcodeSlice.reducer
