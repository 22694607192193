import { LoadingIcon } from "./Icons";

export function LoadingSpinner({ status = false }) {
  if (!status) return null;

  return (
    <div
      className="page-wrapper d-flex justify-content-center align-items-center overflow-hidden"
      style={{ minHeight: "100vh", width: "100vw" }}
    >
      <LoadingIcon className="p-3" />
    </div>
  );
}

export function LoadingPage({ title }) {
  return (
    <div className="page-wrapper">
      <div className="container-xl">
        <div className="page-header d-print-none">
          <div className="row g-2 align-items-center">
            <div className="col">
              <h2 className="page-title">{title}</h2>
            </div>
            <div className="col-auto ms-auto d-print-none">
              <div className="btn-list"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-status-top bg-blue"></div>
                <div className="card-header placeholder-glow">
                  <span
                    className="placeholder rounded me-2"
                    style={{ width: 100, height: 20 }}
                  />
                  <span
                    className="placeholder rounded me-2"
                    style={{ width: 100, height: 20 }}
                  />
                </div>
                <div className="card-body placeholder-glow">
                  <div className="placeholder col-9 mb-3"></div>
                  <div className="placeholder placeholder-xs col-10"></div>
                  <div className="placeholder placeholder-xs col-12"></div>
                  <div className="placeholder placeholder-xs col-11"></div>
                  <div className="placeholder placeholder-xs col-8"></div>
                  <div className="placeholder placeholder-xs col-10"></div>
                  <div className="placeholder placeholder-xs col-10"></div>
                  <div className="placeholder placeholder-xs col-12"></div>
                  <div className="placeholder placeholder-xs col-11"></div>
                  <div className="placeholder placeholder-xs col-8"></div>
                  <div className="placeholder placeholder-xs col-10"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function LoadingTable({ colSpan }) {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="card border-0">
          <div className="card-body placeholder-glow">
            <div className="row">
              <div className="placeholder col-12 mb-3"></div>
              <div className="placeholder col-12 mb-3"></div>
              <div className="placeholder col-12 mb-3"></div>
              <div className="placeholder col-12 mb-3"></div>
              <div className="placeholder col-12 mb-3"></div>
              <div className="placeholder col-12 mb-3"></div>
              <div className="placeholder col-12 mb-0"></div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
}
