import { useSelector } from "react-redux";
import { memo } from "react";

function MessageManager() {
  const messageQueues = useSelector((state) => state.message.messageQueues);

  if (messageQueues.length === 0) {
    return null;
  } else {
    return (
      <div style={{zIndex:1080}} className="position-fixed p-2 top-0 end-0 d-flex flex-column align-items-end overflow-hidden mw-100">
        {messageQueues.map((item, key) => {
          return (
            <div className="card mb-2" key={key}>
              <div
                className={
                  "card-status-top " +
                  (item.type === "success"
                    ? "bg-green"
                    : item.type === "warning"
                    ? "bg-orange"
                    : item.type === "error"
                    ? "bg-red"
                    : item.type === "info"
                    ? "bg-blue"
                    : "bg-blue")
                }
              ></div>
              <div className="card-header py-2 px-3 text-secondary">
                {item.message}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default memo(MessageManager);
