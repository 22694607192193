import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {
    isLoad: false,
    isLogin: false,
    data: {
      permissions: [],
    },
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUserData: (state, value) => {
      state.userData = value.payload;
    },
  },
});

export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
