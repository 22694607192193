export default function Switch({ status, setStatus, disabled }) {
  return (
      <label className={`form-check form-switch mb-0 ${disabled ? 'disabled-switch' : ''}`}>
        <input
            className="form-check-input cursor-pointer"
            type="checkbox"
            checked={status}
            onChange={(e) => setStatus(e.target.checked)}
            disabled={disabled}
        />
      </label>
  );
}
